import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useMapContext from "../Map/useMapContext";
import {
  APPLIANCE_POSITIONS_LAYER_ID,
  APPLIANCE_POSITIONS_SOURCE_ID,
} from "./constants";

interface UseAppliancePositionsMapLayersParams {
  accessToken: string;
}

const useAppliancePositionsMapLayers = ({
  accessToken,
}: UseAppliancePositionsMapLayersParams) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(APPLIANCE_POSITIONS_SOURCE_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: APPLIANCE_POSITIONS_LAYER_ID,
        type: "symbol",
        source: APPLIANCE_POSITIONS_SOURCE_ID,
        layout: {
          "icon-image": [
            "coalesce",
            ["image", ["get", "StatusCategory"]],
            ["image", "appliance-fallback"],
          ],
          "icon-allow-overlap": true,
          "icon-rotate": ["get", "DirectionHor"],
          "icon-rotation-alignment": "map",
        },
      },
      MapLevel.FS_SYMBOLS,
    );

    return () => {
      if (map.getLayer(APPLIANCE_POSITIONS_LAYER_ID)) {
        map.removeLayer(APPLIANCE_POSITIONS_LAYER_ID);
      }
      if (map.getSource(APPLIANCE_POSITIONS_SOURCE_ID)) {
        map.removeSource(APPLIANCE_POSITIONS_SOURCE_ID);
      }
    };
  }, [accessToken, map]);
};

export default useAppliancePositionsMapLayers;
