import { showToast } from "@app/design-system";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { loadImage, type MapImage } from "../utils/loadImage";
import {
  type FeatureServerJson,
  getFeatureServerSymbology,
  type AVLType,
  avlTypes,
} from "./utils";

const fallbackImageMap = {
  appliance: {
    imageId: "appliance-fallback",
    src: "/icons/vehicle-category-not-identified.png",
  },
  portable: {
    imageId: "portable-fallback",
    src: "/icons/radio-status-not-known.png",
  },
} as const satisfies Record<AVLType, MapImage>;

interface FetchSymbologyParams {
  accessToken: string;
  url: string;
}

const fetchSymbology = async ({ accessToken, url }: FetchSymbologyParams) => {
  const response = await fetch(url, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const json = await response.json();

  return json as FeatureServerJson;
};

const useLoadAVLSymbology = ({
  accessToken,
  layerId,
  map,
  symbologyOverrides,
  symbologyUrl,
}: {
  accessToken: string;
  layerId: string;
  map: mapboxgl.Map | maplibregl.Map;
  symbologyOverrides?: Partial<Record<string, string>>;
  symbologyUrl: string;
}) => {
  const { data: symbologyData, isError } = useQuery({
    queryFn: () => fetchSymbology({ accessToken, url: symbologyUrl }),
    queryKey: ["AVL_SYMBOLOGY", layerId],
    retry: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!symbologyData) return;

    const images = getFeatureServerSymbology(symbologyData, symbologyOverrides);

    const loadImages = async () => {
      try {
        await Promise.all(
          [...avlTypes.map((type) => fallbackImageMap[type]), ...images].map(
            (image) => {
              return loadImage({
                ...image,
                map,
              });
            },
          ),
        );
      } catch (error) {
        showToast({
          id: layerId,
          message: "Please reload layer and try again",
          title: "Unable to load AVL symbology",
          variant: "error",
        });
      }
    };

    void loadImages();
  }, [map, layerId, symbologyData, symbologyOverrides]);

  useEffect(() => {
    if (isError) {
      showToast({
        id: "avl-symbology-load-error",
        message: "Please reload layer and try again",
        title: "Unable to load AVL symbology",
        variant: "error",
      });
    }
  }, [isError, layerId]);

  return symbologyData;
};

export default useLoadAVLSymbology;
